import DatePick from "./DatePick";
import Button from "@mui/material/Button";
import LocationList from "./LocationList";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import dataNotFound from "../Grid/DataNotFound";

function Header({
                  headers,
                  setHeaders,
                  setDataNotFound,
                  objectList,
                  setObjectList,
                }) {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState("");
  const [date, setDate] = useState(new Date());
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  let scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  async function authRequest() {
    return {
      Authorization: `Bearer ${await getAccessTokenSilently({
        username: user.email,
      })}`,
    };
  }
/*
  const getObjects = async () => {
    setLoading(true);
    fetch("/urls").then((response) =>
        response.json().then((res) => {
          console.log(res);
          console.log(Object.entries(res));
          // if (res[0].length > 0 && Object.keys(res[1]).length > 0) {
          //   console.log(Object.keys(res[1]));
          //   setHeaders(["Object ID", ...res[0]]);
          // } else {
          //   setHeaders([]);
          // }
          setObjectList(Object.entries(res));
        })
    );
  };
*/
    const getObjects = async () => {
        setLoading(true);
        fetch(
            `https://02usovypvi.execute-api.us-west-2.amazonaws.com/Stage_1/-objects?location=${activeLocation}&date=${date}`,
            {
                headers: new Headers(await authRequest()),
            }
        ).then((response) =>
            response.json().then((res) => {
                console.log(res);
                /*
                if (res[0].length > 0 && Object.keys(res[1]).length > 0) {
                    console.log(Object.keys(res[1]));
                    setHeaders(["Object ID", ...res[0]]);
                } else {
                    setHeaders([]);
                }

                 */
                setObjectList(Object.entries(res));
            })
        );
    };
  useEffect(() => {
    if (objectList) {
      setLoading(false);
    }
  }, [objectList]);

  useEffect(() => {
    if (!loading && objectList.length > 0) {
      scrollTo("image-grid-content");
    }
  }, [loading]);
  useEffect(() => {
    async function fetchData() {
      // You can await here
      console.log(await authRequest());
      fetch(
          "https://02usovypvi.execute-api.us-west-2.amazonaws.com/Stage_1/-locations",
          {
            headers: new Headers(await authRequest()),
          }
      ).then((response) =>
          response.json().then((data) => {
            setLocations(data);
          })
      );
    }
    fetchData();
  }, []);

  return (
      <header className="header-container">
        <DatePick setDate={setDate}></DatePick>
        <LocationList
            locations={locations}
            activeLocation={activeLocation}
            setActiveLocation={setActiveLocation}
        ></LocationList>
        <Button
            disabled={activeLocation === "" || loading}
            onClick={getObjects}
            id="show-grid-button"
        >
          Show Grid
        </Button>
        {loading ? (
            <CircularProgress id="loading-wheel"></CircularProgress>
        ) : null}
      </header>
  );
}
export default Header;

