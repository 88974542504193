import Header from "./Header/Header";
import ImageGrid from "./Grid/ImageGrid";
import {useState} from "react";

function Home() {
    const [objectList, setObjectList] = useState([]);
    const [headers, setHeaders] = useState(null);
    const [dataNotFound, setDataNotFound] = useState(false);
    return (
      <>
        <Header
          setDataNotFound={setDataNotFound}
          setHeaders={setHeaders}
          headers={headers}
          objectList={objectList}
          setObjectList={setObjectList}
        ></Header>
        <ImageGrid
          dataNotFound={dataNotFound}
          headers={headers}
          objectList={objectList}
        ></ImageGrid>
      </>
    );
}

export default Home