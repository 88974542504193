import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StandardGridCell from "./Grid Cells/StandardGridCell";
import GrayGridCell from "./Grid Cells/GrayGridCell";
import HeaderCell from "./Grid Cells/HeaderCell";
import ErrorGridCell from "./Grid Cells/ErrorGridCell";
import DataNotFound from "./DataNotFound";
function ImageGrid({ dataNotFound, headers, objectList }) {
  if (dataNotFound) {
    return (
        <DataNotFound
            key={new Date()}
            message={"No data found. Try another Date."}
        ></DataNotFound>
    );
  }

  return (
      <div className="content-container" id="image-grid-content">
        {!dataNotFound && objectList.length > 0 && (
            <TableContainer
                elevation={1}
                className="object-table-container"
                sx={{
                  width: "99vw",
                  minWidth: "50vw",
                  marginTop: "7px",
                  borderRadius: "3px",
                  maxHeight: "100vh",
                }}
                component={Paper}
            >
              <Table id="object-table" sx={{ tableLayout: "fixed" }}>
                <TableHead
                    sx={{
                      position: "sticky",
                      top: "0px",
                      zIndex: "100",
                      transition: "ease",
                    }}
                >
                  <TableRow
                      sx={{ backgroundColor: "#1d3557", whiteSpace: "nowrap" }}
                  >
                    {headers &&
                        headers.map((item) => {
                          return <HeaderCell item={item}></HeaderCell>;
                        })}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ padding: "0px", overflowY: "scroll" }}>
                  {objectList.map((row) => {
                    return (
                        <TableRow sx={{ height: 50 }} key={row}>
                          <TableCell
                              key={row[0]}
                              align="center"
                              sx={{ padding: "4px 6px", margin: 0, height: 80, fontSize: "25px"}}
                          >
                            {row[0]}
                          </TableCell>
                          {Object.values(row[1]).map((obj, index) => {
                            if (index === 0) {
                              console.log(obj)
                              return (
                                  <StandardGridCell
                                      key={obj.url}
                                      url={obj.url}
                                      timestamp={obj.timestamp}
                                  ></StandardGridCell>
                              );
                            }
                            if (
                                obj.timestamp === null ||
                                obj["timestamp"] === undefined
                            ) {
                              return <ErrorGridCell key={new Date()}></ErrorGridCell>;
                            } else if (obj.timestamp && obj["url"] === undefined) {
                              return (
                                  <GrayGridCell
                                      key={new Date()}
                                      timestamp={obj.timestamp}
                                  ></GrayGridCell>
                              );
                            }
                            return (
                                <StandardGridCell
                                    key={obj.url}
                                    url={obj.url}
                                    timestamp={obj.timestamp}
                                ></StandardGridCell>
                            );
                          })}
                        </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
        )}
      </div>
  );
}
export default ImageGrid;
